import { getSectionTheme } from '@/utils'
import LocalThemeProvider from '@/components/LocalThemeProvider'
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import {
  ContentItemInterface,
  ContentItemUITemplateKeys,
} from '@/interfaces/contentItem'
import {
  Collapse, Container, RichText 
} from '@/components'
import {
  Description, NestedListWrapper, Wrapper 
} from './FAQs.styles'
import { useState } from 'react'
import { PrimaryTitle } from '@/components/WrapperContentItems/components'

const MIN = 3
const MAX = 15

const FAQs: React.FC<WrapperContentInterface> = ({
  theme,
  primaryTitle,
  itemList,
}) => {
  const nestedItems = itemList.filter(
    (item: ContentItemInterface) =>
      item.uiTemplate === ContentItemUITemplateKeys.NestedItem
  )
  if (nestedItems.length < MIN || nestedItems.length > MAX) {
    return null
  }

  // First FAQ item is always open on mount
  const [openedIndex, setOpenedIndex] = useState(0)

  return (
    <LocalThemeProvider theme={{ sectionTheme: getSectionTheme(theme) }}>
      <Wrapper>
        <Container>
          <PrimaryTitle>{primaryTitle}</PrimaryTitle>
          <NestedListWrapper $sectionTheme={theme}>
            {nestedItems.map((item: ContentItemInterface, index: number) => (
              <Collapse
                key={index}
                title={item.title ?? ''}
                isOpened={openedIndex === index}
                onChanged={(isOpened) => setOpenedIndex(isOpened ? index : -1)}
              >
                {item.seoDescription && (
                  <Description $sectionTheme={theme}>
                    <RichText richText={item.seoDescription} />
                  </Description>
                )}
              </Collapse>
            ))}
          </NestedListWrapper>
        </Container>
      </Wrapper>
    </LocalThemeProvider>
  )
}

export default FAQs
